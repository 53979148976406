import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkdownRander = ({ mdData }: { mdData: any }) => {
  return (
    <MarkdownContainer>
      <ReactMarkdown components={{ code: CodeBlock }} remarkPlugins={[remarkGfm]}>
        {mdData}
      </ReactMarkdown>
    </MarkdownContainer>
  );
};

export default MarkdownRander;

const MarkdownContainer = styled.div`
  width: 100%;
  white-space: pre-wrap;
`;

const CodeBlock = styled.div`
  background-color: #efefef;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
`;
