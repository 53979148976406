import styled from 'styled-components';
import React, { useEffect } from 'react';
import { colors } from '../../constants/color';
import ShortcutIcon from '../common/ShortcutIcon';
import { mediaQuery } from '../../util/mediaQuery';
import arrow from '../../assets/images/arrow.json';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { UpDown } from '../../styles/Animation';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Section01 = React.forwardRef<HTMLDivElement>((props, ref) => {
  const lottieArrowRef = React.useRef<LottieRefCurrentProps>(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to('.shape1', {
      scrollTrigger: {
        trigger: '.container',
        start: '50% 40%',
        end: '150% 100%',
        scrub: true,
      },
      transform: 'translate(-130px, -150px) rotate(-75deg) scale(1.2)',
      filter: 'blur(2px)',
    });

    gsap.to('.shape2', {
      scrollTrigger: {
        trigger: '.container',
        start: '50% 40%',
        end: '150% 100%',
        scrub: true,
      },
      transform: 'translate(170px,60px) rotate(59deg) scale(1.2)',
      filter: 'blur(2px)',
    });

    gsap.to('.shape3', {
      scrollTrigger: {
        trigger: '.container',
        start: '50% 40%',
        end: '150% 100%',
        scrub: true,
      },
      transform: 'translate(40px,-170px) scale(1.2)',
      filter: 'blur(2px)',
    });

    gsap.to('.shape4', {
      scrollTrigger: {
        trigger: '.container',
        start: '50% 40%',
        end: '150% 100%',
        scrub: true,
      },
      transform: 'translate(-140px,-170px) scale(1.2)',
      filter: 'blur(2px)',
    });

    gsap.to('.shape5', {
      scrollTrigger: {
        trigger: '.container',
        start: '50% 40%',
        end: '150% 100%',
        scrub: true,
      },
      transform: 'translate(40px,-170px) scale(1.2)',
      filter: 'blur(2px)',
    });

    gsap.to('.bt-bg', {
      scrollTrigger: {
        trigger: '.container',
        start: '50% 40%',
        end: '140% 100%',
        scrub: true,
      },
      opacity: 1,
    });

    gsap.to('.mt-2', {
      scrollTrigger: {
        trigger: '.container',
        start: '50% 40%',
        end: '160% 100%',
        scrub: true,
      },
      backgroundSize: '130%',
      duration: 5,
    });
  }, []);

  return (
    <Container ref={ref} className="container">
      <Wrap className="flex">
        <TextSection>
          <div className="main-title-container">
            <div className="main-title">
              <p className="mt-0">PortFolio</p>
              <p className="mt-1">FRONTEND</p>
              <div className="mt-2" data-text="DEV">
                DEVELOPER
              </div>
            </div>
          </div>
        </TextSection>
        <ShortcutIconContainer>
          <ShortcutIcon />
        </ShortcutIconContainer>
      </Wrap>

      {/* 구름 이미지 */}
      <Image>
        <img src="/assets/images/1.jpg" alt="" />
        <ImageObject2>
          <img src="/assets/images/cloud_drawing.png" alt="구름" />
        </ImageObject2>
      </Image>

      {/* 노트북 이미지 */}
      <Image2>
        <div className="image-part">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="url(#img1)"
              d="M48,-43C58.5,-25,60.9,-5.3,57.5,14.5C54.1,34.3,44.9,54.2,29.4,62.1C13.9,69.9,-7.9,65.7,-27.4,56.5C-47,47.2,-64.3,32.9,-66.1,17.3C-67.9,1.6,-54.2,-15.3,-40.6,-34C-27,-52.6,-13.5,-72.9,2.6,-75C18.7,-77.1,37.4,-60.9,48,-43Z"
              transform="translate(100 100)"
            />
            <defs>
              <pattern id="img1" viewBox="0 0 200 200" width="100%" height="100%" x="0" y="0">
                <image
                  href="/assets/images/2.jpg"
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="xMinYMin slice"
                />
              </pattern>
            </defs>
          </svg>
        </div>
        <div className="frame-part">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="none"
              stroke="#fff"
              strokeWidth="1"
              d="M48,-43C58.5,-25,60.9,-5.3,57.5,14.5C54.1,34.3,44.9,54.2,29.4,62.1C13.9,69.9,-7.9,65.7,-27.4,56.5C-47,47.2,-64.3,32.9,-66.1,17.3C-67.9,1.6,-54.2,-15.3,-40.6,-34C-27,-52.6,-13.5,-72.9,2.6,-75C18.7,-77.1,37.4,-60.9,48,-43Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </Image2>

      <ScrollDown>
        <div className="button">
          <Lottie animationData={arrow} loop={true} lottieRef={lottieArrowRef} />
        </div>
      </ScrollDown>

      <Shape1 className="shape1">
        <UpDown time={2} transformy={-10}>
          <img src="/assets/images/shape_1.png" alt="원통 모양" />
        </UpDown>
      </Shape1>

      <Shape2 className="shape2">
        <UpDown time={3} transformy={-4}>
          <img src="/assets/images/shape_2.png" alt="지렁이 모양" />
        </UpDown>
      </Shape2>

      <Shape3 className="shape3">
        <UpDown time={1} transformy={-4}>
          <img src="/assets/images/shape_3.png" alt="세모" />
        </UpDown>
      </Shape3>

      <Shape4 className="shape4">
        <UpDown time={3} transformy={-5}>
          <img src="/assets/images/shape_4.png" alt="작은 원통형" />
        </UpDown>
      </Shape4>

      <Shape5 className="shape5">
        <UpDown time={2} transformy={-10}>
          <img src="/assets/images/shape_5.png" alt="별모양" />
        </UpDown>
      </Shape5>
      <BG>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
          <defs>
            <pattern id="p" width="100" height="100" patternUnits="userSpaceOnUse" patternTransform="scale(0.22)">
              <path data-color="outline" fill="none" stroke="#F6F6EB" strokeWidth="7.35" d="M50 0v100M100 50H0"></path>
            </pattern>
          </defs>
          <rect fill="#F3F2E3" width="100%" height="100%"></rect>
          <rect fill="url(#p)" width="100%" height="100%"></rect>
        </svg>
      </BG>
      <BottomBg className="bt-bg"></BottomBg>
    </Container>
  );
});

export default Section01;

const Container = styled.section`
  width: 100%;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  transition: all 0.3s;
  height: 100vh;

  &.change_header {
    height: 0vh;
  }
`;

const Wrap = styled.div`
  width: 90%;
  height: 100vh;
  margin: 0 auto;

  &.flex {
    display: flex;
  }
`;

const TextSection = styled.div`
  width: 100%;
  padding: 100px;
  position: relative;
  z-index: 4;
  margin-top: 13%;
  height: fit-content;

  ${mediaQuery.desktop`padding: 80px; margin-top: 12%;`}
  ${mediaQuery.tablet`padding: 60px; position: absolute; bottom:3%; left:0; right:0; margin: 0 auto;`}
  ${mediaQuery.mobile`padding: 30px; bottom:10%;`}

  .main-title-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    ${mediaQuery.tablet`width: auto;`}

    .main-title {
      font-family: 'Cafe24Moyamoya-Regular-v1.0';
      font-weight: 800;
      font-size: 7rem;
      letter-spacing: 5px;
      color: #000;
      transition: all 0.3s;

      p.mt-0 {
        font-family: 'Pretendard-Regular';
        font-weight: 600;
        font-size: 0.85rem;
        color: #364e8a;
      }

      p.mt-1 {
        font-weight: 600;
        font-size: 6rem;
      }

      div.mt-2 {
        margin-top: -30px;
        font-weight: 800;
        font-size: 6.5rem;
        //color: transparent;

        /* background-image: url('/assets/images/text-bg2.png');
        background-clip: text;
        background-size: 60%;
        -webkit-background-clip: text; */
      }

      ${mediaQuery.desktop`font-size: 3.7em;`}
      ${mediaQuery.tablet`font-size: 3.6rem; letter-spacing: -2px; text-align: center;`}
      ${mediaQuery.mobile`font-size: 2rem; letter-spacing: -2px; text-align: center;`}
    }

    .main-obj {
      width: 90%;
      position: absolute;
      z-index: 1;

      top: 0%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .main-title-obj {
      position: absolute;
      width: 100%;
      height: 80px;
      background-color: ${colors.mainColor};
      top: 0px;
      right: 0;

      animation: fillOut 3s 0.3s forwards;
      ${mediaQuery.desktop`height: 60px;`}
      ${mediaQuery.mobile`height: 33px;`}

      @keyframes fillOut {
        0% {
          width: 100%;
        }
        100% {
          width: 0%;
        }
      }
    }

    .main-title-obj2 {
      position: absolute;
      width: 100%;
      height: 80px;
      background-color: ${colors.mainColor};
      top: 85px;
      left: 0;
      animation: fillOut 3s 0.6s forwards;
      ${mediaQuery.desktop`height: 60px; top: 65px;`}
      ${mediaQuery.mobile`height: 33px; top: 35px;`}

      @keyframes fillOut {
        0% {
          width: 100%;
        }
        100% {
          width: 0%;
        }
      }
    }

    .main-title-obj3 {
      position: absolute;
      width: 100%;
      height: 80px;
      background-color: ${colors.mainColor};
      top: 160px;
      right: 0;
      animation: fillOut 3s 0.9s forwards;
      ${mediaQuery.desktop`height: 60px; top: 125px;`}
      ${mediaQuery.mobile`height: 33px; top: 70px;`}

      @keyframes fillOut {
        0% {
          width: 100%;
        }
        100% {
          width: 0%;
        }
      }
    }
  }

  .subText-container {
    width: 100%;
    display: flex;
    align-items: end;
    ${mediaQuery.tablet`display: inherit; text-align: center;`}
  }
`;

const ScrollDown = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0%;
  left: 10%;
  ${mediaQuery.mobile`display: none;`}

  .button {
    width: 22px;
    border: none;

    ${mediaQuery.mobile`width: 130px;`}
  }
`;

const ShortcutIconContainer = styled.div`
  position: absolute;
  right: -200px;
  top: 20px;
  opacity: 0.6;
  z-index: 4;

  animation: reveal2 1s forwards;
  @keyframes reveal2 {
    100% {
      right: 90px;
      opacity: 1;
    }
  }

  ${mediaQuery.tablet`
  animation: reveal2 1s forwards;
  @keyframes reveal2 {
    100% {
      right: 20px;
      opacity: 1;
    }
  }`}
`;

const Image = styled.div`
  background-color: transparent;
  position: absolute;
  top: 12%;
  right: 8%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQuery.tablet`right:0; left:0; margin: 0 auto; top:8%;`}

  &::before {
    content: '';
    width: 300px;
    height: 100%;
    display: block;
    background-color: transparent;
    border-radius: 150px 150px 0px 0px;
    border: 2px solid #fff;

    position: absolute;
    top: 6px;
    left: 8px;

    ${mediaQuery.tablet`left: initial; margin-left: -10px;`}
    ${mediaQuery.mobile`width: 210px;`}
  }

  img {
    width: 300px;
    border-radius: 150px 150px 0px 0px;
    object-fit: cover;

    ${mediaQuery.mobile`width: 210px;`}
  }
`;

const Image2 = styled.div`
  width: 550px;
  height: 200px;
  background-color: transparent;
  position: absolute;
  bottom: 32%;
  right: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .image-part {
    width: 100%;

    pattern {
      transform: scale(1.2);
    }
  }

  .frame-part {
    width: 100%;
    position: absolute;
    top: -83%;
    left: 6px;
    z-index: 3;
  }
`;

const ImageObject2 = styled.div`
  position: absolute;
  top: 33%;
  right: -2%;
  z-index: 1;

  img {
    width: 250px;
    filter: brightness(1);
  }
`;

// 하단 3D도형 스타일 적용
const Shape1 = styled.div`
  position: absolute;
  bottom: 230px;
  left: 30px;
  z-index: 5;

  img {
    width: 60%;
  }
`;

const Shape2 = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;

  img {
    width: 60%;
  }
`;

const Shape3 = styled.div`
  position: absolute;
  top: 32%;
  left: 50%;

  z-index: 2;

  img {
    width: 120px;
  }
`;

const Shape4 = styled.div`
  position: absolute;
  top: 100px;
  left: 100px;

  img {
    width: 70%;
  }
`;

const Shape5 = styled.div`
  position: absolute;
  top: 45%;
  right: 55px;

  img {
    width: 100%;
  }
`;

const BG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  z-index: -1;
`;

const BottomBg = styled.div`
  width: 100%;
  height: 700px;
  background-image: linear-gradient(to bottom, transparent 10%, #000 100%);

  position: absolute;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  z-index: 10;
`;
