import styled from 'styled-components';

interface UpDownProps {
  time?: number;
  transformy?: number;
}

export const UpDown = styled.div<UpDownProps>`
  animation: upDown ${({ time = 2 }) => time}s ease-out 0s infinite alternate;

  @keyframes upDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(${({ transformy = -5 }) => transformy}px);
    }
  }
`;
