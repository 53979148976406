import styled from 'styled-components';
import { colors } from '../constants/color';
import { mediaQuery } from '../util/mediaQuery';

export const Wrapper = styled.div`
  width: 85%;

  margin: 0 auto;
  color: ${colors.normal};

  &.flex {
    display: flex;
  }
`;

export const TitleText = styled.div`
  position: relative;
  width: fit-content;
  height: 60px;

  h2 {
    font-family: 'Orbitron', sans-serif;
    color: #fff;
    font-size: 3rem;
    letter-spacing: 2.3px;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    width: 0%;
    overflow: hidden;

    ${mediaQuery.desktop`font-size: 2.5rem;`}
    ${mediaQuery.tablet`font-size: 2.2rem;`}
    ${mediaQuery.mobile`font-size: 1.4rem;`}
  }

  .box {
    width: 0%;
    height: 60px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: -10px;
    mix-blend-mode: overlay;
    transform: skew(-30deg);

    &.black {
      background-color: #000;
      height: 160px;
    }

    &.second {
      height: 160px;
      left: -40px;
    }
  }

  .copy {
    font-family: 'Orbitron', sans-serif;
    font-size: 3rem;
    letter-spacing: 2.3px;
    font-weight: 700;
    color: #636363;
    z-index: 0;
    width: 100%;

    &.light {
      color: #a5a5a5;
    }
  }

  h2.black {
    color: #000;
    font-size: 3.2rem;
    font-weight: 700;

    ${mediaQuery.desktop`font-size: 2.5rem;`}
    ${mediaQuery.tablet`font-size: 2.2rem;`}
    ${mediaQuery.mobile`font-size: 2rem;`}
  }

  .point {
    color: ${colors.mainColor};
    font-weight: bold;
  }

  .title-1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #000;
  }

  p {
    margin-top: 10px;
    color: #777;
    font-size: 15px;
  }
`;

export const SubText = styled.div`
  margin-top: 5px;

  font-size: 17px;
  color: ${colors.mainTextColor};
`;
