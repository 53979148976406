import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import styled from 'styled-components';
import Section01 from '../components/main/Section01';
import Section03 from '../components/main/Section03';
import Section04 from '../components/main/Section04';
import Section05 from '../components/main/Section05';
import Modal from '../components/UI/Modal';
import Footer from '../components/UI/Footer';
import LoadingPage from '../components/common/LoadingPage';
import Section02 from '../components/main/Section02';
import Section06 from '../components/main/Section06';

const Main = () => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const loadRef = useRef<HTMLDivElement | null>(null);

  const isOpen = useSelector((state: RootState) => {
    return state.modal.isVisible;
  });

  useEffect(() => {
    setTimeout(() => {
      if (loadRef.current) {
        loadRef.current.classList.add('finish');
      }
    }, 3000);
  });

  return (
    <Container>
      <LoadingPage ref={loadRef} />
      {isOpen ? <Modal /> : null}
      <Section01 ref={sectionRef} />
      <Section02 />
      <Section03 />
      <Section06 />
      <Section04 />
      <Section05 />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default Main;
