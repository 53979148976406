import styled from 'styled-components';
import SimpleCardList from '../common/SimpleSkilList';
import { useEffect, useRef, useState } from 'react';
import { TitleText, Wrapper } from '../../styles/CommonStyles';
import { skill } from '../../mock/skill';
import { mediaQuery } from '../../util/mediaQuery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

type Section03Props = {
  onClick?: () => void;
};

type TabItemProps = {
  selected: boolean;
  number: number;
};

const Section03 = ({ onClick }: Section03Props) => {
  const [tab, setTab] = useState<number>(0);
  const sec03Ref = useRef<HTMLDivElement>(null);
  const sec03TitleRef = useRef<HTMLDivElement>(null);

  const tabHandler = (index: number) => {
    setTab(index);
  };

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to('.title-1 .box', {
      scrollTrigger: {
        trigger: '.container3',
        start: '0% 40%',
        end: '40% 40%',
        scrub: true,
      },
      width: '120%',
      duration: 7,
    });

    gsap.to('.list', {
      scrollTrigger: {
        trigger: '.container3',
        start: '0% 50%',
        end: '40% 50%',
        scrub: true,
      },
      x: 0,
      opacity: 1,
    });
  }, []);

  return (
    <Container onClick={onClick} className="container3" ref={sec03Ref}>
      <Wrapper>
        <LeftContent>
          <TitleText ref={sec03TitleRef}>
            <div className="title-1">
              <div className="box black second"></div>
              <div className="copy light">WEB SKILL</div>
            </div>
          </TitleText>
          <TabName>
            {skill.map((item, index) => (
              <TabItem
                key={`skill-${index}`}
                onClick={() => tabHandler(index)}
                className={index === tab ? 'menu select' : 'menu'}
                selected={index === tab}
                number={item.content.length}
              >
                {item.skillName}
              </TabItem>
            ))}
          </TabName>
        </LeftContent>
        <RightContent className="rc">
          <SimpleCardList data={skill[tab].content} className="list" />
        </RightContent>
      </Wrapper>
    </Container>
  );
};

export default Section03;

const Container = styled.section`
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .bg {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0px;
    left: 0;

    .custom-shape-divider-top-1712770197 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      line-height: 0;
    }

    .custom-shape-divider-top-1712770197 svg {
      position: relative;
      display: block;
      width: calc(100% + 1.3px);
      height: 130px;
    }
  }
`;

const LeftContent = styled.div`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 130px;
  border-bottom: 2px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  ${mediaQuery.desktop`display: flex; justify-content: space-between;`}
  ${mediaQuery.tablet`flex-direction: column;`}
`;

const TabName = styled.ul`
  display: flex;
  gap: 30px;
  font-family: 'Orbitron', sans-serif;

  ${mediaQuery.desktop`flex-direction: row; gap: 30px;`}
  ${mediaQuery.mobile`gap: 5%;`}
`;

const TabItem = styled.li<TabItemProps>`
  position: relative;
  border: 2px solid #cfcfcf;
  border: ${props => (props.selected ? '2px solid #444;' : '2px solid #cfcfcf;')};
  border-radius: 50px;
  height: 50px;
  line-height: 40px;
  font-size: 26px;
  color: ${props => (props.selected ? '#000' : '#cfcfcf;')};
  font-weight: 600;
  transition: 0.3s;
  padding: 2px 35px;

  &:after {
    content: '${props => props.number}';
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: red;
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 30px;
  }
`;

const RightContent = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;

  ${mediaQuery.desktop`margin-top: 130px;`}
  ${mediaQuery.tablet` width: 100%; padding-left:0px;`}
  ${mediaQuery.mobile`margin-top: 30px;`}
`;
