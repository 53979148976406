import styled from 'styled-components';
import { Link } from 'react-router-dom';

type ButtonProps = {
  onClick?: () => void;
  text: string;
  url?: string;
  btndesign?: string;
};

const Button = ({ text, url, btndesign, onClick }: ButtonProps) => {
  return (
    <ButtonContainer btndesign={btndesign} onClick={onClick}>
      <LinkItem btndesign={btndesign} to={url ? url : ''}>
        {text}
      </LinkItem>
    </ButtonContainer>
  );
};

export default Button;

const ButtonContainer = styled.div<{ btndesign?: string }>`
  width: 100%;
  padding: 7px 10px;
  /* padding: ${props => (props.btndesign ? ' 7px 10px' : '0px')}; */
  background-color: ${props => (props.btndesign ? '#689ffd' : 'transparent')};
  border: 1px solid ${props => (props.btndesign ? 'transparent' : '#689ffd')};
  text-align: center;
  cursor: pointer;
`;

const LinkItem = styled(Link)<{ btndesign?: string }>`
  display: block;
  font-weight: 500;
  color: ${props => (props.btndesign ? '#fff' : '#93b0e2')};
  height: 25px;
  line-height: 25px;
`;
