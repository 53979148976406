import { tripsketch } from './markdown/tripsketch';
import { refreshBookstore } from './markdown/refreshBookstore';
import { popular } from './markdown/popular';
import { mbti } from './markdown/mbti';
import { mub } from './markdown/mub';

export const portfolioData = [
  {
    name: '트립스케치',
    urlGit: 'https://github.com/seoyeon-00/tripsketch',
    urlPage: 'https://github.com/seoyeon-00/tripsketch',
    description:
      '여행일기를 공유하는 플랫폼입니다. 리액트 네이티브, expo를 사용하여 제작되었으며 사용자들과 여행기록을 공유하고 소통하는 공간입니다.',
    period: '2023.06.29 ~ 2023.09.27',
    productPart: '(FrontEnd) 게시물 상세 페이지 / 작성 및 수정 페이지',
    deployUrl: 'https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000771698',
    skill: {
      frontend: 'React-native Expo, styled-Components, Recoil, React-Query',
      backend: 'Spring-boot, kotlin, mongodb',
    },
    image: '/assets/images/portfolio_bg_1.jpg',
    modalData: tripsketch.text,
    person: '7명 (프론트엔드 3명 / 백앤드 3명 / PM 1명)',
    outline:
      '코로나 시대가 저물어가면서 해외나 국내로 여행을 다니는 사람들이 많아졌습니다. 기존 SNS나 블로그에서 찾아볼 수 있는 방대한 정보는 서치를 통하여 도달할 수 있습니다. "여행 정보만 모아서 다양한 여행지에 선택지를 줄 수 있는 플랫폼이 없을까?" 트립스케치는 다양한 여행지를 한눈에 볼 수 있고 정보룰 공유할 수 있는 플랫폼입니다.',

    perform: [
      {
        title: '게시물 상세 페이지',
        des: [
          'React Query를 활용하여 데이터 fetch. 데이터를 UI에 맞게 레이아웃 구성',
          '댓글 영역 bottom-sheet로 구현. KeyboardAvoidingView로 키보드와 입력창 겹치는 현상 방지',
          'nominatim OSM을 활용하여 위치 데이터를 시각적으로 제공',
        ],
      },
      {
        title: '게시물 작성 및 수정페이지',
        des: [
          'manipulateAsync를 사용해 이미지 resize 진행. 이미지 깨짐 방지를 위해 원본 사이즈 크기대비 resize 비율 조정',
          '위치 검색 기능 Debouncing을 적용하여 데이터 fetch 요청을 효율적으로 개선',
          '하나의 컴포넌트에서 updateId의 존재 여부에 따라 작성, 수정 페이지 조건부 렌더링하여 재사용성 높임',
        ],
      },
      {
        title: 'Code Push',
        des: [
          '오류나 UI를 수정하기 위해 번거로운 심사과정 없이 앱을 업데이트할 수 있도록 코드푸시 채택',
          'EAS CLI를 사용하여 지정한 빌드에 업데이트할 수 있도록 기능을 추가',
        ],
      },
    ],
    troubleShooting: [
      {
        title: '이모지 활용하기',
        des1: {
          name: 'react-native-flags 사용으로 이미지 화질 및 크기 문제 파악',
          des: ['이미지 화질 저하 및 assets 크기 증가로 인해 사용성 저하 및 Code Push 기능에 부정적인 영향을 미침.'],
        },
        des2: {
          name: '국기 이모지 활용 및 이미지 assets 최적화 활용하기',
          des: [
            '모든 유니코드는 코드 포인트를 할당 받음. 국기 이모지를 이용하여 이미지 대신 표현함으로써, 이미지 화질 및 크기 문제를 해결',
            '이미지 assets을 최적화하여 빌드 시간을 단축.',
          ],
        },
        des3: {
          name: '적용결과',
          des: [
            '이미지 해상도 및 퀄리티를 조정하여 사용자에게 더 나은 시각적 경험을 제공',
            'Code Push 기능을 성공적으로 적용하여, 이전에 발생했던 assets 이슈를 해결',
          ],
        },
      },
    ],
  },
  {
    name: 'POPULAR',
    urlGit: 'https://github.com/seoyeon-00/popular',
    urlPage: 'https://popular-wheat.vercel.app/',
    description: '현재 진행중이거나 오픈 예정인 팝업스토어를 한눈에 확인할 수 있는 서비스입니다.',
    period: '2023.05.26 ~ 2023.06.17',
    productPart: '(FrontEnd) 메인페이지, 회원정보 관리, 프로필 페이지',
    deployUrl: 'https://popular-wheat.vercel.app/',
    skill: {
      frontend: 'React, React Query, Redux, Typescript, Vite, styled-component',
      backend: 'Nest.js, Typescript, MongoDB, JWT',
    },
    image: '/assets/images/portfolio_bg_2.jpg',
    modalData: popular.text,
    person: '8명 (프론트엔드 6명 / 백앤드 2명)',
    outline:
      '현재 한국은 팝업스토어가 유행하고 있습니다. 소비자와 현장에서 소통할 수 있고 다양한 볼거리를 제공하는 팝업스토어는 MZ세대에서 인기를 끌고 있습니다. 팝업스토어는 특정 기간동안만 방문할 수 있는 이벤트성 스토어, 체험관이므로 방문 전 정보가 필수적입니다. 이런 팝업스토어에 열광하는 한국 트랜드에서 스토어에 대한 정보를 모아놓은 플랫폼이 필요하다고 생각했습니다. POPULAR는 팝업스토어의 정보, 공유 및 함께 동행할 수 있는 커뮤니티와 팝업스토어 지도를 제공하는 플랫폼입니다.',
    perform: [
      {
        title: '메인페이지 제작',
        des: [
          'scroll-snap 속성을 활용하여 CSS, Javascript로 캐러셀을 구현',
          '로그인 여부 및 사용자의 선호 카테고리에 따라 조건부 렌더링 적용',
        ],
      },
      {
        title: '회원정보 관리',
        des: ['유저 정보 PATCH 요청을 보내어 변경된 정보 업데이트', '수정 항목 유효성 검사 진행'],
      },
      {
        title: '프로필 페이지',
        des: ['동적으로 데이터를 불러오기 위해 useInfiniteQuery 활용'],
      },
    ],
    troubleShooting: [],
  },
  {
    name: '리프레시 북스토어',
    urlGit: 'https://github.com/seoyeon-00/refresh-bookstore-frontend',
    urlPage: 'https://www.refreshbook.store/',
    description:
      '개발자들이 필요한 서적들을 모아놓은 서점 "리프레시 북스토어"입니다. 1차 프로젝트로 Vanilla JS, express.js로 제작한 프로젝트로 next.js과 spring-boot로 리팩토링하여 새로운 UI로 재탄생했습니다.',
    period: '2023.06.24 ~',
    productPart: '(BackEnd) User 관련기능 (FrontEnd) 어드민 / 로그인 기능 구현',
    deployUrl: 'https://www.refreshbook.store/',
    skill: {
      frontend: 'Nextjs, recoil, tailwind',
      backend: 'kotlin, Spring boot, exposed, JWT',
    },
    image: '/assets/images/portfolio_bg_3.jpg',
    modalData: refreshBookstore.text,
    person: '4명 (프론트엔드 2명 / 백앤드 2명)',
    outline:
      '프론트엔드 개발 공부를 시작한 팀원들의 공통 관심사는 개발에 관한 서적이었습니다. 개발 서적을 모아놓은 서비스가 있으면 좋겠다고 생각했습니다.',
    perform: [
      {
        title: '[FrontEnd] 어드민',
        des: ['회원, 도서, 카테고리, 주문 관리에 대한 ADMIN 페이지 구현'],
      },
      {
        title: '[FrontEnd] 로그인 기능 구현',
        des: [
          'Axios Interceptor를 이용하여 토큰 검증 및 갱신 처리',
          'ContextAPI를 사용하여 전역적에서 로그인 상태 관리',
        ],
      },
      {
        title: '[BackEnd] 사용자',
        des: [
          '로그인 JWT access Token 발급, 검증, 갱신',
          '회원정보 수정, 탈퇴 기능 구현',
          '회원가입 유효성 및 중복검사',
        ],
      },
      {
        title: '[BackEnd] 이메일 발송',
        des: [
          '회원가입 이메일 인증 기능',
          '배송상태 변경 시 사용자에게 이메일 발송',
          '이메일, 생년월일 검증 후 임시 비밀번호 발급',
        ],
      },
    ],
    troubleShooting: [],
  },
  {
    name: 'MUB',
    urlGit: 'https://github.com/seoyeon-00/tripsketch',
    urlPage: 'https://www.mub-movie.store/',
    description:
      '오픈 API "TMDB"를 사용하여 영화 데이터를 호출했으며, supabase를 통한 회원정보, storage 이미지 저장 기능을 사용하여 백엔드 없이 데이터베이스를 구축하였습니다.',
    period: '2024.03.15 ~ 2024.04.03',
    productPart: 'UI 디자인 및 프론트엔드 웹 개발',
    deployUrl: 'https://www.mub-movie.store/',
    skill: {
      frontend: 'Next.js, Supabase, Zustand, Tailwind CSS',
      backend: '',
    },
    image: '/assets/images/portfolio_mub.png',
    modalData: mub.text,
    person: '1명 (개인 프로젝트)',
    outline:
      '오래된 명작 부터 영화관에서 볼 수 있는 최신 영화까지 영화를 전문적으로 다루는 OTT 서비스가 있으면 집에서도 편하게 최신 영화를 감상할 수 있겠다는 생각에 해당 서비스를 구상하게 되었습니다.',
    perform: [
      {
        title: 'FrontEnd 개발',
        des: [
          '웹 페이지의 레이아웃 디자인 및 프론트엔드 UI를 구현',
          'SSR으로 초기 렌더링 및 SEO 최적화',
          'ContextAPI를 사용하여 전역적에서 로그인 상태 관리',
        ],
      },
      {
        title: 'Supabase',
        des: ['OAuth 이용하여 로그인 구현. Github과 이메일 계정 인증을 통한 회원가입'],
      },
    ],
    troubleShooting: [],
  },
  {
    name: 'MBTI TEST',
    urlGit: 'https://github.com/seoyeon-00/tripsketch',
    urlPage: 'https://funny-faloodeh-79b256.netlify.app/',
    description: '나의 MBTI와 일치하는 이모지들을 확인할 수 있는 초간단 MBTI TEST.',
    period: '2023.12.29 ~ 2024.01.03',
    productPart: 'UI 디자인 및 기능 개발',
    deployUrl: 'https://funny-faloodeh-79b256.netlify.app/',
    skill: {
      frontend: 'React, Styled-Component, typescript',
      backend: '',
    },
    image: '/assets/images/mbti3.png',
    modalData: mbti.text,
    person: '1명 (개인 프로젝트)',
    outline:
      '문항이 많은 복잡한 MBTI 테스트가 아닌, 최소한의 문제로 빠르고 간편한 MBTI 테스트를 할 수 있는 페이지를 제작했습니다.',
    perform: [],
    troubleShooting: [],
  },
];
