import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaQuery } from '../../util/mediaQuery';

const ShortcutIcon = () => {
  return (
    <Container>
      <Link to={'https://github.com/seoyeon-00'}>
        <div className="text">Github</div>
      </Link>
      <Link to={'https://velog.io/@strawsunny'}>
        <div className="text">Velog</div>
      </Link>
      <Link to={'https://www.notion.so/74d82a1b8811437f8585a99c6cd979f9'}>
        <div className="text">Notion</div>
      </Link>
    </Container>
  );
};

export default ShortcutIcon;

const Container = styled.div`
  padding: 15px 25px;
  display: flex;
  gap: 50px;

  ${mediaQuery.mobile`padding: 10px 15px; gap: 10px;`}

  .text {
    color: #111;
    font-weight: 600;
    font-size: 0.85rem;
    letter-spacing: 3px;
  }
`;
