import styled from 'styled-components';
import { Wrapper } from '../../styles/CommonStyles';

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <Text>copyright ©2023 Kim Seo Yeon PF. All rights reserved.</Text>
      </Wrapper>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  background: linear-gradient(90deg, #147be2 0%, rgba(80, 66, 245, 1) 35%, rgba(0, 212, 255, 1) 100%);
  padding: 30px 0px 40px;
`;

const Text = styled.div`
  color: #efefef;
  font-size: 14px;
  text-align: center;
`;
