export const tripsketch = {
  text: `
  # 🛬 Trip sketch
  ***
  **여행을 다녀온 사람들이 여행일기를 공유하는 서비스 입니다.**

  ![트립스케치](https://i.esdrop.com/d/f/V3n7QYUh8O/O7yEixgg97.png)
  &nbsp; 

  ## 1. 프로젝트 소개
  여행에 관한 정보를 공유하는 서비스는 많지만 여행 경험, 감성 등 여행 그 자체를 공유하는 서비스는 부족합니다. 요즘 많은 여행 유튜버들이 인기를 얻고 있는데, 여행 유튜버들을 통해 대리 여행을 통해 간접 경험을 하고 대리만족을 얻듯이 여행 그 자체를 공유하는 서비스를 통해 여행을 하고 싶은 사람들에게 그 감성을 얻게 하고자 하였습니다.

  #### 📕 다운로드 
  현재 원스토어에서 트립스케치 어플리케이션을 사용할 수 있습니다.
  > ### [🎲 원스토어 트립스케치 다운로드](https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000771698)

  #### 📗 개발 기간
  2023.06.29 ~ 2023.09.27
  

  ## 2. 멤버 구성

  ![역할](https://i.esdrop.com/d/f/V3n7QYUh8O/tYHQYJw7mt.png)

  ## 3. 기술 스택
  
  ![스킬](https://i.esdrop.com/d/f/V3n7QYUh8O/3uNRcFGtp8.png)

  &nbsp;  
  &nbsp;

  ## 4. 서비스 주요기능 소개
  #### 📕 아키텍쳐 
  ![트립스케치아키텍쳐](https://i.esdrop.com/d/f/V3n7QYUh8O/817mWjV4aw.png)

  #### 📗 화면 구성 및 기능
  ![주요기능](https://i.esdrop.com/d/f/V3n7QYUh8O/BSq5KskHc2.png)
  ![화면구성](https://i.esdrop.com/d/f/V3n7QYUh8O/ImYqHDlevA.png)


  ## 5. 시작 가이드

  #### 📕 필요한 개발 환경
  * Npm 9.5.1
  * Node.js 18.16.0
  * expo 49.0.7

  #### 📗 설치
  \`\`\`
  $ npm install -g expo-cli
  $ npm install
  $ npm start
  \`\`\`



  `,
};
