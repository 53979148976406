import { styled } from 'styled-components';
import { Skill } from '../../types/Skill';

const SkillItem = ({ data }: { data: Skill }) => {
  return (
    <Container>
      <div className="image-content">
        <div className="item-image">
          <img src={data.image} alt={data.name} />
        </div>
        <div className="item-name">{data.name}</div>
      </div>
    </Container>
  );
};

export default SkillItem;

const Container = styled.div`
  width: 100%;

  .image-content {
    width: 100%;
    height: 65px;
    background-color: #181818;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .item-image {
      width: 65px;
      padding: 5% 6%;

      img {
        width: 100%;
        mix-blend-mode: lighten;
      }
    }

    .item-name {
      margin-left: 20px;
    }
  }
`;
