import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../store';
import { closeModal } from '../../features/modal/modalSlice';
import { colors } from '../../constants/color';
import MarkdownRander from '../common/MarkdownRander';

const Modal = () => {
  const dispach = useDispatch();
  const itemData = useSelector((state: RootState) => state.modal.itemData);
  const closeHandler = () => {
    dispach(closeModal(null));
  };

  return (
    <ModalContainer>
      <Overlay></Overlay>
      <ModalContent>
        <CloseBtn onClick={closeHandler}>x</CloseBtn>
        <Content>
          <MarkdownRander mdData={itemData} />
        </Content>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  width: 100vw;
  position: fixed;
  z-index: 10;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
`;

const ModalContent = styled.div`
  width: 100vw;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0px;
  padding: 90px;

  animation: revealUp 0.5s;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @keyframes revealUp {
    0% {
      transform: translateY(150px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

const CloseBtn = styled.div`
  position: fixed;
  right: 30px;
  top: 15px;

  width: 40px;
  height: 40px;
  background-color: ${colors.mainColor};
  border-radius: 50px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  opacity: 0;

  animation: revealUp2 1s 0.6s forwards;

  @keyframes revealUp2 {
    0% {
      top: 30px;
      opacity: 0;
    }
    100% {
      top: 15px;
      opacity: 1;
    }
  }
`;

const Content = styled.div``;
