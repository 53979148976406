import GlobalStyles from './GlobalStyles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import { useEffect } from 'react';
import lenis from './util/lenis';

function App() {
  useEffect(() => {
    lenis();
  }, []);
  return (
    <BrowserRouter>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Main />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
