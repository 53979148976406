export const refreshBookstore = {
  text: `# 📚 Refresh Bookstore
  ***
  개발자들의 북스토어! "리프레시 북스토어"에서는 개발자와 개발을 공부하는 학생들이 필요한 도서를 구매할 수 있는 도서 플랫폼입니다.
  개발 공부를 시작하기 위해 필요한 새로운 언어와 지식을 배우기 위해, 항상 새로운 가짐으로 배움을 얻고자 REFRESHBOOK STORE가 탄생하게 되었습니다.

  ### 1. 서비스 소개

  #### 📕 기술스택
  프론트엔드  Next.js / Recoil / axios 
  백엔드 kotlin / spring boot / exposed / swagger

  #### 📗 개발 기간
  2023.06.24 ~ 

  #### 📘 배포 URL
  [▪️ API 명세서서](https://port-0-refresh-bookstore-20zynm2mlk1daxmm.sel4.cloudtype.app/swagger-ui/index.html)
  [▪️ 프로젝트 바로가기](https://www.refreshbook.store/)
  
  ### 2. 멤버 소개
  ![역할](https://i.esdrop.com/d/f/V3n7QYUh8O/Vbn8gq6Zov.png)


  ### 3. 서비스 주요기능 소개
  ![메인](https://i.esdrop.com/d/f/V3n7QYUh8O/9jAJrY1pZS.png)
  ![상세](https://i.esdrop.com/d/f/V3n7QYUh8O/Eg63Fmpcw6.png)
  ![주문](https://i.esdrop.com/d/f/V3n7QYUh8O/xyyPC0v10K.png)
  ![마이페이지](https://i.esdrop.com/d/f/V3n7QYUh8O/jeYgeb5mRi.png)
  ![어드민](https://i.esdrop.com/d/f/V3n7QYUh8O/CO998rWvxZ.png)

  ### 4. 실행방법
  \`\`\`
  npm install
  npm run dev
  \`\`\`
  
  `,
};
