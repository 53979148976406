import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface modalProps {
  isVisible: boolean;
  itemData: string | null;
}

const initialState: modalProps = { isVisible: false, itemData: null };

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<string>) => {
      state.isVisible = true;
      state.itemData = action.payload;
    },
    closeModal: (state, action) => {
      state.isVisible = false;
      state.itemData = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
