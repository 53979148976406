export const mub = {
  text: `
  # 😶 MUB
  ***
  &nbsp; 

  ## 1. 프로젝트 소개
  오래된 명작 부터 영화관에서 볼 수 있는 최신 영화까지 영화를 전문적으로 다루는 OTT 서비스가 있으면 집에서도 편하게 최신 영화를 감상할 수 있겠다는 생각에 해당 서비스를 구상하게 되었습니다.

  #### 📕 배포 URL
  [▪️ 프로젝트 바로가기](https://www.mub-movie.store/)

  #### 📗 개발 기간
  2024.03.15 ~ 2024.04.03
  
  ## 2. 기술 스택
  
  ▪️ Next.js, Supabase, Zustand, Tailwind CSS

  ## 3. 서비스 주요기능 소개
  - ▪️ 최신 영화, 카테고리 별 추천 영화 등 사용자가 선호하는 데이터 제공

  ## 4. 시작 가이드

  #### 📗 설치
  \`\`\`
  $ npm install
  $ npm start
  \`\`\`



  `,
};
