import styled from 'styled-components';
import { TitleText, Wrapper } from '../../styles/CommonStyles';
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Section02 = () => {
  const sec02Ref = useRef<HTMLDivElement>(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to('.title .box', {
      scrollTrigger: {
        trigger: '.container2',
        start: '0% 40%',
        end: '50% 40%',
        scrub: true,
      },
      width: '120%',
      duration: 7,
    });

    gsap.to('.profile', {
      scrollTrigger: {
        trigger: '.container2',
        start: '20% 40%',
        end: '80% 40%',
        scrub: true,
      },
      position: 'sticky',
      top: '0',
      left: '0',
      duration: 7,
    });

    gsap.utils.toArray('.text-content-item').forEach(item => {
      ScrollTrigger.create({
        trigger: item as gsap.DOMTarget,
        start: 'top 80%',
        end: 'bottom 20%',
        scrub: 2,
        onEnter: () => {
          animate(item);
        },
        onLeaveBack: () => {
          resetAnimation(item);
        },
      });

      const animate = (item: any) => {
        gsap.to(item, {
          opacity: 1,
          x: 0,
          y: '-40px',
          duration: 2,
          transform: 'scale(1)',
        });
      };

      const resetAnimation = (item: any) => {
        gsap.to(item, {
          opacity: 0,
          y: '0px',
          duration: 2,
          transform: 'scale(1.03)',
        });
      };
    });

    gsap.to('.container2', {
      scrollTrigger: {
        trigger: '.container2',
        start: '85% 90%',
        end: '100% 90%',
        scrub: true,
      },
      backgroundColor: '#fff',
    });
  }, []);

  return (
    <Container className="container2" ref={sec02Ref}>
      <Wrapper>
        <TitleText className="title">
          <div className="box"></div>
          <div className="copy">ABOUT ME</div>
        </TitleText>
        <Contents>
          <ImageCt className="profile">
            <div className="image-profile">
              <img src="/assets/images/memoji.png" alt="미모지" />
            </div>
          </ImageCt>
          <TextCt>
            <ul>
              <li className="text-content-item item-01">
                <h3>About Me</h3>
                <div className="text">
                  <p>
                    <span className="text-bold">Name</span>김서연 (KIM SEO YEON)
                  </p>
                  <p>
                    <span className="text-bold">Birth</span>1994.11.10
                  </p>
                  <p>
                    <span className="text-bold">Abbress</span>경기도 고양시
                  </p>
                </div>
              </li>
              <li className="text-content-item item-02">
                <h3>학력 및 경력사항</h3>
                <div className="text">
                  - 2014.02 ~ 2018.10 강원대학교 식물자원응용과학 전공
                  <br />- 2019.06 ~ 2019.12 이젠아카데미 모바일 앱 & 웹디자인 교육 수료
                  <br />- 2020.02 ~ 2022.10 위시드인베스트먼트 디자인팀 근무
                  <br />- 2023.02 ~ 2023.06 엘리스 SW엔지니어 트랙 4기 수료
                </div>
              </li>
              <li className="text-content-item item-03">
                <h3>KEY WORD</h3>
                <div className="text">
                  <div className="i3-text-box">
                    <p>
                      <b># 도전</b>
                    </p>
                    <p>
                      수차례 팀 프로젝트를 진행하며 다양한 기술을 사용했습니다. 새로 접해보는 기술은 저에게 값진
                      경험이며 발전을 위한 밑거름입니다. 변화하는 기술에 대하여 빠르게 대응하겠습니다.
                    </p>
                  </div>
                  <div className="i3-text-box">
                    <p>
                      <b># 책임감</b>
                    </p>
                    <p>
                      한번 저에게 주어진 역할은 끝날 때까지 저의 임무입니다. 맡겨 주신 업무에 대해 책임감을 가지고
                      수행하겠습니다.
                    </p>
                  </div>
                </div>
              </li>
              <li className="text-content-item item-04">
                <h3>HELLO</h3>
                <div className="text">
                  프론트엔드 개발자 김서연입니다.
                  <br />
                  광고대행회사에서 웹디자인 및 웹퍼블리셔로 근무하면서, 웹 개발 분야에 대한 흥미와 열정을 느껴
                  프론트엔드 개발자로의 전환을 결정했습니다. 엘리스트랙에서의 4개월 동안의 교육은 제게 새로운 도전과
                  배움의 기회를 제공했습니다. 두 번의 프로젝트 경험을 통해 실전에서의 개발 능력을 향상시키고, 교육을
                  성공적으로 완료했습니다.
                  <br />
                  <br />
                  이후, 더 많은 경험과 도전을 원하며 두 번의 팀 프로젝트를 진행하면서 협업 능력과 문제 해결 능력을
                  강화하였습니다. 이 프로젝트들에서의 경험은 제게 다양한 기술 스택 및 협업 동적을 이해하고, 프로젝트
                  수행 능력을 향상시키는 데 큰 역할을 했습니다.
                  <br />
                  <br />
                  저는 끊임없는 학습과 성장을 추구하며, 사용자 중심의 멋진 사용자 경험을 제공하기 위해 노력하고
                  있습니다. 웹 개발 분야에서의 열정과 창의성을 통해 현실적이고 효과적인 솔루션을 찾는 것이 제
                  목표입니다. 훌륭한 팀원이 되어 팀의 목표를 달성하는 데 기여하고, 지속적인 기술적인 도전을 통해
                  성장하는 개발자가 되고자 합니다. 더 많은 기회를 통해 다양한 프로젝트에 참여하고 성장하는 것을
                  기대하며, 새로운 도전을 두려워하지 않고 받아들이는 개발자로 남고 싶습니다.
                </div>
              </li>
            </ul>
          </TextCt>
        </Contents>
      </Wrapper>
    </Container>
  );
};

export default Section02;

const Container = styled.section`
  background-color: #000;
  padding: 15% 0px 15%;
`;

const Contents = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
`;

const ImageCt = styled.div`
  width: 40%;
  height: 80vh;
  padding-top: 5%;
  display: flex;
  justify-content: center;
  position: relative;

  .image-profile {
    width: 350px;
    height: 350px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TextCt = styled.div`
  width: 60%;
  padding-top: 5%;

  ul {
    padding: 20px;
    box-sizing: border-box;
  }

  .text-content-item {
    background-color: rgba(35 36 39);
    padding: 5%;
    border-radius: 10px;
    line-height: 26px;
    margin-bottom: 50px;
    color: #969696;
    font-size: 18px;
    line-height: 30px;
    transform: scale(1.03);
    h3 {
      letter-spacing: 1px;
    }
    .text {
      margin-top: 10px;
    }
  }

  .item-01 {
    .text-bold {
      display: inline-block;
      width: 100px;
      font-weight: 500;
    }
  }

  .item-03 {
    .i3-text-box {
      margin: 5px 0px;
    }
  }
`;
