import { styled } from 'styled-components';
import { Wrapper } from '../../styles/CommonStyles';
import Mail from '../../assets/icons/Mail';
import Github from '../../assets/icons/Github';
import { mediaQuery } from '../../util/mediaQuery';

const Section05 = () => {
  return (
    <Container id="contact">
      <Wrapper>
        <ContactContent>
          <BgTitle>
            <div className="title">CONTACT</div>
          </BgTitle>
          <ContactTemporary>
            <div>
              <div className="icon-inner">
                <div className="icon-container">
                  <Mail className="icon" />
                </div>
                <div className="text">이메일</div>
                <div className="text-english">Email</div>
                <div className="line"></div>
                <div className="icon-data">ksoup3434@gmail.com</div>
              </div>
            </div>
            <div>
              <div className="icon-inner">
                <div className="icon-container">
                  <Github className="icon" />
                </div>
                <div className="text">깃허브</div>
                <div className="text-english">github</div>
                <div className="line"></div>
                <div className="icon-data">github.com/seoyeon-00</div>
              </div>
            </div>
          </ContactTemporary>
        </ContactContent>
      </Wrapper>
    </Container>
  );
};

export default Section05;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  background: linear-gradient(90deg, #147be2 0%, rgba(80, 66, 245, 1) 35%, rgba(0, 212, 255, 1) 100%);
  padding: 100px 0px;
  position: relative;
  transition: all 3s;
`;

const ContactContent = styled.div`
  margin-top: 50px;
  display: flex;

  ${mediaQuery.tablet`display: initial;`}
`;

const BgTitle = styled.div`
  width: 35%;
  color: #f8f8f8;

  .title {
    font-size: 3.5rem;
    letter-spacing: 2.3px;
    font-weight: 700;
    font-family: 'Orbitron', sans-serif;
  }
  .sub {
    font-size: 1rem;
  }

  ${mediaQuery.tablet`width: 100%; font-size: 4rem; text-align:center;`}
`;

const ContactTemporary = styled.div`
  width: 65%;
  padding-left: 100px;
  padding-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: start;
  color: #000;

  ${mediaQuery.tablet`width: 100%; font-size: 1rem; padding-left:0px; text-align:center; padding-top: 50px; justify-content: space-around;`}
  ${mediaQuery.mobile`flex-direction: column;`}

  > div {
    width: 48%;
    ${mediaQuery.mobile`width: 100%;`}
  }

  .icon-inner {
    height: 100%;

    .icon-container {
      width: 50px;
      height: 50px;

      .icon {
        fill: #ffffff;
        width: 45px;
        height: 45px;

        ${mediaQuery.tablet`width: 24px;`}
      }
    }

    .text {
      margin-top: 15px;
      font-size: 30px;
      font-weight: 700;
      color: #c5d7f6;

      ${mediaQuery.tablet`font-size: 16px;`}
      ${mediaQuery.mobile`font-size: 0px;`}
    }

    .text-english {
      margin-top: 5px;
      font-size: 16px;
      color: #8baae3;
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: #93afdf;
      margin: 30px 0px;
    }

    .icon-data {
      font-size: 1.2rem;
      color: #c5d7f6;
      transition: all 0.3s;

      ${mediaQuery.tablet`font-size: 15px;`}
      ${mediaQuery.mobile`width: fit-content; color: #333; padding-left: 10px;`}
    }
  }
`;
