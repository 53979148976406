import styled from 'styled-components';
import Button from '../UI/Button';
import { Portfolio } from '../../types/Portfolio';
import { useDispatch } from 'react-redux';
import { openModal } from '../../features/modal/modalSlice';
import { mediaQuery } from '../../util/mediaQuery';
import { colors } from '../../constants/color';
import { useState } from 'react';

type ItemProps = {
  portfolioData: Portfolio;
  index: number;
};

type ContainerItemProps = {
  items: number | string;
};

const PortFolioItem = ({ portfolioData, index }: ItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispach = useDispatch();
  const readMeHandler = () => {
    dispach(openModal(portfolioData.modalData));
  };

  const contentHandler = () => {
    setIsOpen(!isOpen);
  };

  const itemNumber = index < 9 ? '0' + (index + 1) : index + 1;

  return (
    <ContainerItem items={itemNumber}>
      <Image>
        <img src={portfolioData.image} alt="포트폴리오 이미지" />
        <div className="image-container">
          <div className="image-title">{portfolioData.name}</div>
          <div className="image-description">{portfolioData.description}</div>

          <div className="image-button">
            <Button text={'page'} url={portfolioData.deployUrl} />
            <Button text={'github'} url={portfolioData.urlGit} btndesign={'border'} />
          </div>
        </div>
      </Image>
      <PFItem>
        <PFDescription>
          <div className="content-box">
            <div className="content-info">
              <div>
                <span className="info-title">👨‍👦‍👦 개발 인원</span>
                <span className="info-description">{portfolioData.person}</span>
              </div>
              <div>
                <span className="info-title">🕓 제작 기간</span>
                <span className="info-description">{portfolioData.period}</span>
              </div>
              <div>
                <span className="info-title">🔥 제작 파트</span>
                <span className="info-description">{portfolioData.productPart}</span>
              </div>
              <div className="description-long-container">
                <span className="info-title">🔧 사용한 스킬</span>
                <div className="info-description-long">
                  <div className="description-long-inner">
                    <p className="dl-title">프론트엔드</p>
                    <p className="dl-des">{portfolioData.skill.frontend}</p>
                  </div>
                  <div className="description-long-inner">
                    {portfolioData.skill.backend.length === 0 ? null : (
                      <div>
                        <p className="dl-title">백엔드</p>
                        <p className="dl-des">{portfolioData.skill.backend}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="expand-button">
            <button onClick={contentHandler}>MORE VIEW</button>
          </div>
          {isOpen ? (
            <div>
              <div className="content-box-2">
                <div>
                  <div className="content-box-2-title">개요</div>
                  <div className="content-box-2-des">{portfolioData.outline}</div>
                </div>
                {portfolioData.perform.map(item => (
                  <div>
                    <div className="content-box-2-title">{item.title}</div>
                    {item.des.map(item => (
                      <li className="content-box-2-des">{item}</li>
                    ))}
                  </div>
                ))}
                <div>
                  <div className="content-box-2-title">
                    {portfolioData.troubleShooting.length > 0 ? '트러블슈팅' : ''}
                  </div>
                  {portfolioData.troubleShooting.length > 0 ? (
                    <div>
                      {portfolioData.troubleShooting.map(item => (
                        <div>
                          <li className="content-box-2-des trouble-title">
                            <span>{item.title}</span>
                            <ul className="trouble-des">
                              <li>
                                <div className="trouble-des-name">{item.des1.name}</div>
                                <div className="trouble-des-description">
                                  {item.des1.des.map(item => (
                                    <li>{item}</li>
                                  ))}
                                </div>
                              </li>
                              <li>
                                <div className="trouble-des-name">{item.des2.name}</div>
                                <div className="trouble-des-description">
                                  {item.des2.des.map(item => (
                                    <li>{item}</li>
                                  ))}
                                </div>
                              </li>
                              <li>
                                <div className="trouble-des-name">{item.des3.name}</div>
                                <div className="trouble-des-description">
                                  {item.des3.des.map(item => (
                                    <li>{item}</li>
                                  ))}
                                </div>
                              </li>
                            </ul>
                          </li>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div className="content-button">
                <div className="btn-width-50">
                  <Button onClick={readMeHandler} text={'READ ME'} />
                </div>
                <div className="btn-width-50">
                  <Button text={'github'} url={portfolioData.urlGit} btndesign={'border'} />
                </div>
              </div> */}
            </div>
          ) : null}
        </PFDescription>
      </PFItem>
    </ContainerItem>
  );
};

export default PortFolioItem;

const ContainerItem = styled.div<ContainerItemProps>`
  position: relative;
  width: 100%;
  height: auto;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;

  ${mediaQuery.desktop`flex-direction: column;`}
  ${mediaQuery.tablet`padding: 0px;`}

  &:before {
    content: '${props => props.items}';
    display: none;
    width: 220px;
    height: 220px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    opacity: 0.07;

    color: #979797;
    font-size: 120px;
    font-weight: 700;
    font-style: italic;
  }
`;

const Image = styled.div`
  width: 100%;
  overflow: hidden;
  height: 400px;
  position: relative;

  ${mediaQuery.desktop`height: 320px;`}
  ${mediaQuery.mobile`height: 210px;`}

  img {
    width: 100%;
    filter: brightness(0.23);
    transition: all 0.3s;

    ${mediaQuery.desktop`width: 100%; height: 320px;`}
    ${mediaQuery.mobile`height: 210px;`}
  }

  .image-container {
    position: absolute;
    width: 75%;
    height: 100%;
    top: 90px;
    left: 60px;

    .image-title {
      font-size: 2.5rem;
      font-weight: 700;
      color: #fff;
      letter-spacing: 3px;
    }

    .image-description {
      margin-top: 20px;
      font-size: 1.1rem;
      line-height: 26px;
      color: #9c9c9c;
    }

    .image-button {
      margin-top: 60px;
      width: 50%;
      display: flex;
      gap: 7px;

      position: absolute;
      bottom: 120px;
      right: -22%;
      z-index: 6;
    }
  }
`;

const PFItem = styled.div`
  width: 100%;
  //background-color: #fff;
  background-color: transparent;
  transition: all 0.5s;

  ${mediaQuery.desktop`width: 100%;`}
`;

const PFDescription = styled.div`
  padding: 20px 40px 50px;
  transition: all 0.3s;
  ${mediaQuery.desktop`padding: 40px 0px;`}
  ${mediaQuery.tablet`padding:6% 6% 15% 6%;`}


  .content-box {
    margin-top: 5px;

    .content-info {
      margin-top: 30px;
      background-color: #f2f2f2;
      color: #000;
      padding: 25px 25px;
      border-radius: 10px 0px 10px 0px;
      transform: translateY(10px);

      > div {
        margin: 7px 0px;
      }

      .info-title {
        display: inline-block;
        width: 130px;
        font-weight: 600;
        font-size: 1rem;

        ${mediaQuery.tablet`font-size: 0.9rem;`}
        ${mediaQuery.mobile`font-size: 0.85rem; display: block;`}
      }

      .info-description {
        font-size: 1rem;
        ${mediaQuery.tablet`font-size: 0.9rem;`}
        ${mediaQuery.mobile`font-size: 0.85rem; display: block; margin-top:3px;`}
      }

      .description-long-container {
        display: flex;
        ${mediaQuery.mobile`display: initial`}

        .info-description-long {
          width: calc(100% - 130px);
          display: inline-block;

          ${mediaQuery.mobile`display: block; width:100%;`}
        }

        .description-long-inner {
          ${mediaQuery.mobile`margin-top:3px;`}

          .dl-title {
            font-weight: 600;
            margin-bottom: 5px;

            ${mediaQuery.tablet`font-size: 0.9rem;`}
            ${mediaQuery.mobile`font-size: 0.82rem;`}
          }

          .dl-des {
            margin-bottom: 10px;
            color: #555;
            ${mediaQuery.tablet`font-size: 0.9rem;`}
            ${mediaQuery.mobile`font-size: 0.85rem;`}
          }
        }
      }
    }
  }

  .expand-button {
    margin-top: 50px;
    text-align: center;

    button {
      width: 300px;
      height: 45px;
      background-color: #ededed;
      border: none;
      font-size: 12px;
      font-weight: 600;
      transition: all 0.3s;

      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  .content-box-2 {
    margin-top: 50px;
    color: #000;

    > div {
      margin-bottom: 40px;
    }

    .content-box-2-title {
      font-weight: 600;
      font-size: 20px;

      ${mediaQuery.tablet`font-size: 1.1rem;`}
      ${mediaQuery.mobile`font-size: 0.85rem`}
    }

    .content-box-2-des {
      margin-top: 5px;
      line-height: 30px;
      list-style: square;
      ${mediaQuery.tablet`font-size: 0.95rem; letter-spacing:0.5px;`}
      ${mediaQuery.mobile`font-size: 0.9rem`}

      .trouble-des {
        padding: 0px 30px;
        ${mediaQuery.mobile`padding: 0px 10px;`}

        li {
          font-weight: 400;
          font-size: 16px;
          ${mediaQuery.tablet`font-size: 0.95rem;`}
          ${mediaQuery.mobile`font-size: 0.9rem`}

          .trouble-des-name {
            font-weight: 600;
          }

          .trouble-des-description {
            li {
              list-style: square;
            }
          }
        }
      }
    }

    .trouble-title {
      font-weight: 500;
      font-size: 18px;
      ${mediaQuery.tablet`list-style: none;`}
      ${mediaQuery.mobile``}
      list-style:none;

      span {
        display: inline-block;
        background-color: ${colors.mainColor};
        padding: 2px 12px;
        color: #e3e3e3;
        border-radius: 25px;
        font-size: 11px;
        font-weight: 600;

        ${mediaQuery.tablet`font-size: 0.7rem; padding: 1px 10px; letter-spacing:-0.5px;`}
        ${mediaQuery.mobile`font-size: 0.65rem`}
      }
    }
  }

  .content-button {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 7px;
    transform: translateY(10px);
    font-size: 0.9rem;

    .btn-width-50 {
      width: 50%;
    }
  }
`;
