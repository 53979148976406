import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const LoadingPage = React.forwardRef<HTMLDivElement>((props, ref) => {
  const progressRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let addNum = 0;
    let intervalId: any;
    function say() {
      if (progressRef.current && addNum < 100) {
        addNum += 4;
        progressRef.current.style.setProperty('width', `${addNum}%`);
        progressRef.current.style.setProperty('transition', 'all 0.3s');
      }
    }

    setTimeout(() => {
      intervalId = setInterval(say, 100);

      // 6초 후에 setInterval을 종료
      setTimeout(() => {
        clearInterval(intervalId);
      }, 3000);
    }, 0);
  }, []);

  return (
    <Container ref={ref}>
      <LoadingInner>
        <LoadingImage>
          <div className="progress" ref={progressRef}></div>
        </LoadingImage>
      </LoadingInner>
    </Container>
  );
});

export default LoadingPage;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #181818;
  position: fixed;
  top: 0;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  &.finish {
    transform: translate(0, -100%);
    transition: all 1s;
  }
`;

const LoadingInner = styled.div`
  margin: 0 auto;
  width: 15%;
  height: 4px;

  position: relative;
`;

const LoadingImage = styled.div`
  width: 100%;
  height: 4px;
  background-color: #4a4a4a;
  border-radius: 50px;
  overflow: hidden;

  .progress {
    width: 0%;
    height: 4px;
    background-color: #2f1eec;
  }
`;
