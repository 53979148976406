import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Wrapper } from '../../styles/CommonStyles';
import PortFolioItem from '../common/PortFolioItem';
import { portfolioData } from '../../mock/portfolio';
import { mediaQuery } from '../../util/mediaQuery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Section04 = React.forwardRef<HTMLDivElement>((props, ref) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const sec04Ref = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;

    if (sectionRef.current) {
      if (scrollPosition >= 2800) {
        sectionRef.current.classList.add('change_header');
      } else {
        sectionRef.current.classList.remove('change_header');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to('.title-custom', {
      scrollTrigger: {
        trigger: '.container4',
        start: '10% 40%',
        end: '40% 40%',
        scrub: true,
      },
      position: 'sticky',
      top: '0',
      left: '0',
      duration: 7,
    });

    gsap.to('.title-custom .c1', {
      scrollTrigger: {
        trigger: '.container4',
        start: '10% 40%',
        end: '40% 40%',
        scrub: true,
      },
      width: '100%',
      duration: 6,
    });

    gsap.to('.title-custom .c2', {
      scrollTrigger: {
        trigger: '.container4',
        start: '15% 40%',
        end: '40% 40%',
        scrub: true,
      },
      width: '100%',
      duration: 6,
    });
  }, []);

  return (
    <Container className="container4" ref={sec04Ref}>
      <Wrapper>
        <TitleCustom className="title-custom">
          <div className="title-1">
            <p className="txt">FRAMEWORK</p>
            <p className="copy c1">FRAMEWORK</p>
          </div>
          <div className="title-2">
            <p className="txt">PORTFOLIO</p>
            <p className="copy c2">PORTFOLIO</p>
          </div>
        </TitleCustom>
        <ContainerInner>
          <PFTogether>
            {portfolioData.map((item, index) => (
              <div key={`item-${index}`} className="item">
                <PortFolioItem portfolioData={item} index={index} />
              </div>
            ))}
          </PFTogether>
        </ContainerInner>
      </Wrapper>
    </Container>
  );
});

export default Section04;

const Container = styled.section`
  position: relative;
  width: 100%;
  background-color: #000;
  padding: 30% 0px 15%;

  .exclamation {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #888;
    text-align: center;
    margin-right: 5px;
    border-radius: 50%;

    color: #181818;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
  }
`;

const TitleCustom = styled.div`
  position: sticky;
  top: 0;
  padding: 140px 0px;

  > div {
    position: relative;
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: 4px;
    font-family: 'Orbitron', sans-serif;
  }

  .txt {
    color: #333;
  }

  .copy {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    color: #cfcfcf;
  }

  .c1 {
    width: 0%;
  }

  .c2 {
    width: 0%;
  }
`;

const ContainerInner = styled.div`
  margin-top: 1400px;

  position: relative;
  z-index: 4;
`;

const PFTogether = styled.div`
  position: relative;
  z-index: 6;

  > div {
    margin-bottom: 120px;
    ${mediaQuery.mobile`margin-bottom: 80px;`}
  }
`;
