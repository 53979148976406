import { styled } from 'styled-components';
import { Skill } from '../../types/Skill';
import SkillItem from './SkillItem';

interface CardContainerProps {
  length: number;
}

const SimpleCardList = ({ data, className }: { data: Skill[]; className?: string }) => {
  return (
    <Container length={data.length} className={className}>
      {data.map((item, index) => (
        <div key={`skill-${index}`}>
          <SkillItem data={item} />
        </div>
      ))}
    </Container>
  );
};

export default SimpleCardList;

const Container = styled.div<CardContainerProps>`
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.length % 5 === 0 ? 'space-between' : 'flex-start')};
  gap: 10px;
  transform: translate(-600px, 0);
  opacity: 0.1;

  > div {
    width: 19%;
    margin-bottom: 60px;
  }
`;
