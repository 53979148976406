import { styled } from 'styled-components';
import { TitleText, Wrapper } from '../../styles/CommonStyles';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';

const Section06 = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to('.title .box', {
      scrollTrigger: {
        trigger: '.container6',
        start: '0% 40%',
        end: '50% 40%',
        scrub: true,
      },
      width: '120%',
      duration: 7,
    });

    gsap.to('.content-list > li:nth-child(odd)', {
      scrollTrigger: {
        trigger: '.container6',
        start: '30% 40%',
        end: '80% 40%',
        scrub: true,
      },
      y: 0,
      duration: 7,
    });

    gsap.to('.content-list > li:nth-child(even)', {
      scrollTrigger: {
        trigger: '.container6',
        start: '30% 40%',
        end: '80% 40%',
        scrub: true,
      },
      y: 0,
      duration: 7,
    });
  }, []);

  return (
    <Container className="container6">
      <Wrapper>
        <TitleText>
          <TitleText className="title">
            <div className="box"></div>
            <div className="copy">WEB PUBLISHING</div>
          </TitleText>
        </TitleText>
        <Contents>
          <ul className="content-list">
            <li className="item item01">
              <a href="https://clrud84.cafe24.com/" target="_blank" rel="noreferrer">
                <div className="logo">
                  <img src="/assets/images/logo-1.png" alt="로고" />
                </div>
                <div className="image">
                  <img src="/assets/images/pb1.png" alt="선바이오 연구소" />
                </div>
              </a>
            </li>
            <li className="item item02">
              <a href="https://ksoup33.cafe24.com/nts2022/cash/pc/index.html" target="_blank" rel="noreferrer">
                <div className="logo">
                  <img src="/assets/images/logo-2.png" alt="로고" />
                </div>
                <div className="image">
                  <img src="/assets/images/pb2.png" alt="국세청1" />
                </div>
              </a>
            </li>
            <li className="item item03">
              <a href="https://ksoup33.cafe24.com/nts2021/pc/index.html" target="_blank" rel="noreferrer">
                <div className="logo">
                  <img src="/assets/images/logo-2.png" alt="로고" />
                </div>
                <div className="image">
                  <img src="/assets/images/pb3.png" alt="국세청2" />
                </div>
              </a>
            </li>
            <li className="item item04">
              <a href="https://ksoup33.cafe24.com/plusmnm/" target="_blank" rel="noreferrer">
                <div className="logo">
                  <img src="/assets/images/logo-4.png" alt="로고" />
                </div>
                <div className="image">
                  <img src="/assets/images/pb4.png" alt="plusmnm" />
                </div>
              </a>
            </li>
          </ul>
        </Contents>
      </Wrapper>
    </Container>
  );
};

export default Section06;

const Container = styled.div`
  width: 100%;
  height: 100vh;

  padding: 15% 0px;
  background-color: #000;
`;

const Contents = styled.div`
  margin-top: 100px;
  width: 100%;
  height: 500px;

  .content-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 500px;
    gap: 4px;

    > li:nth-child(odd) {
      transform: translate(0, 30px);
    }

    > li:nth-child(even) {
      transform: translate(0, -30px);
    }

    .item {
      width: 24%;
      border-radius: 10px;
      position: relative;
      overflow: hidden;

      a {
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
      }

      .logo {
        width: 160px;
        padding-top: 100px;
        margin: 0px auto;
        display: flex;

        img {
          width: 100%;
          filter: brightness(1000%);
        }
      }

      .image {
        width: 310px;
        position: absolute;
        top: 58%;
        left: 0px;
        right: 0;
        margin: 0 auto;

        img {
          width: 100%;
        }
      }
    }

    .item01 {
      background-color: #1456ff;
    }

    .item02 {
      background-color: #6528e1;
    }

    .item03 {
      background-color: #fabd2a;
    }

    .item04 {
      background-color: #0471e9;
    }
  }
`;
