export const mbti = {
  text: `
  # 😶 이모지로 보는 MBTI TEST
  ***
  ![이모지로 보는 MBTI TEST](https://i.esdrop.com/d/f/V3n7QYUh8O/pqDg4XYwk7.png)
  &nbsp; 

  ## 1. 프로젝트 소개
  나의 MBTI는 무엇일까? 간단하게 나의 MBTI를 검사하면서 나와 어울리는 이모지를 확인해 볼 수 있는 프로젝트입니다.

  #### 📕 배포 URL
  [▪️ 프로젝트 바로가기](https://funny-faloodeh-79b256.netlify.app/)

  #### 📗 개발 기간
  2023.12.29 ~ 2024.01.03
  
  ## 2. 기술 스택
  
  ▪️ React, Styled-Component, typescript

  ## 3. 서비스 주요기능 소개
  - ▪️URL 공유기능
  - ▪️MBTI TEST 
  - ▪️MBTI 결과 조건부 렌더링

  ## 4. 시작 가이드

  #### 📗 설치
  \`\`\`
  $ npm install
  $ npm start
  \`\`\`



  `,
};
