import { css } from 'styled-components';

type deviceProps = {
  desktop: number;
  tablet: number;
  mobile: number;
};

const sizes: deviceProps = {
  desktop: 1200,
  tablet: 768,
  mobile: 575,
};

// media query 모듈 함수
export const mediaQuery = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce((acc, key) => {
  acc[key] = (style: string) => css`
    @media (max-width: ${sizes[key]}px) {
      ${style}
    }
  `;
  return acc;
}, {} as { [key: string]: Function });
