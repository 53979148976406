import Lenis from '@studio-freight/lenis';

const lenis = () => {
  const lenis = new Lenis({
    duration: 3,
  });

  function raf(time: any) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
};

export default lenis;
