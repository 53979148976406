export const popular = {
  text: `# 🎁 POPULAR
  ***
  **팝업스토어에 관련된 정보를 교환하고 경험을 공유하는 커뮤니티 플랫폼**
  
  ![이미지](https://i.esdrop.com/d/f/V3n7QYUh8O/GYviMCKAKW.png)

  ### 1. 서비스 소개

#### 📕 프로젝트 소개
**POPULAR**는 팝업스토어에 관련된 정보를 교환하고 경험을 공유하는 커뮤니티 플랫폼입니다.
팝업스토어는 다양한 분야에서 창의적인 아이디어와 제품을 볼 수 있는 아주 특별한 컨셉 스토어입니다.
**POPULAR**에서 팝업스토어의 매력에 대해서 서로 공유하며, 새로운 동료를 만나보세요!

#### 📗 개발 기간
2023.05.26 ~ 2023.06.17

#### 📘 배포 URL
[▪️ API 명세서서](https://port-0-popular-6w1j2allzfh0gg.sel5.cloudtype.app/swagger)
[▪️ 서비스 바로가기](https://www.popular-popup.store/)

### 2. 멤버 소개

#### Frontend
![프론트엔드](https://i.esdrop.com/d/f/V3n7QYUh8O/e2g4siiqYD.png)

#### Backend
![백엔드](https://i.esdrop.com/d/f/V3n7QYUh8O/MDEQ45FSlm.png)

### 3. 기술 스택
#### Frontend
![프론트엔드](https://i.esdrop.com/d/f/V3n7QYUh8O/3AoBDPT1z4.png)

#### Backend
![백엔드](https://i.esdrop.com/d/f/V3n7QYUh8O/gW1968YnQo.png)

### 4. 서비스 주요기능 소개


### 5. 커뮤니케이션
Figma, Swagger, Discord, Notion 사용하여 팀 협업을 진행했습니다.

#### Swagger
![스웨거](https://i.esdrop.com/d/f/V3n7QYUh8O/vNAyEUILzj.png)
#### Figma
![피그마](https://i.esdrop.com/d/f/V3n7QYUh8O/XosT7rCFQT.png)
`,
};
