export const skill = [
  {
    skillName: 'Frontend',
    content: [
      {
        name: 'HTML',
        image: '/assets/html5.svg',
        skillStage: 5,
        description:
          'HTML 문서의 구조와 태그를 이해하고 마크업이 가능하며 브라우저별 크로스 브라우징 활용할 수 있습니다',
      },
      {
        name: 'CSS',
        image: '/assets/css3.svg',
        skillStage: 5,
        description:
          'css 선택자 사용 방법을 이해하며, 다양한 패턴 및 레이아웃을 구성할 수 있습니다. 미디어쿼리로 디바이스 크기에 따른 스타일을 동적으로 조정할 수 있습니다.',
      },
      {
        name: 'JavaScript',
        image: '/assets/javascript.svg',
        skillStage: 4,
        description:
          'JavaScript의 핵심적인 문법과 데이터 타입을 이해하고 활용할 수 있으며 Promise, Async/Await 등을 활용하여 비동기적인 작업을 다룰 수 있습니다.',
      },
      {
        name: 'React',
        image: '/assets/react.svg',
        skillStage: 4,
        description:
          '컴포넌트를 활용하여 UI를 재사용 가능하고 유지보수 용이하게 구조화. useState, useEffect, useContext 등의 Hooks를 사용하여 함수형 컴포넌트에서 상태와 생명주기를 관리합니다.',
      },
      {
        name: 'Redux',
        image: '/assets/redux.svg',
        skillStage: 3,
        description:
          '데이터 전역 상태관리를 위해 Redux를 사용합니다. 액션과 리듀서를 통해 상태를 효과적으로 관리할 수 있습니다.',
      },
      {
        name: 'typescript',
        image: '/assets/typescript.svg',
        skillStage: 3,
        description: 'TypeScript를 활용하여 코드의 가독성을 향상시키고 타입 안정성을 확보할 수 있습니다.',
      },
      {
        name: 'python',
        image: '/assets/python.svg',
        skillStage: 3,
        description: '데이터 수집을 위해 파이썬을 이용하여 웹 크롤링을 활용할 수 있습니다.',
      },
      {
        name: 'tailwind',
        image: '/assets/tailwind.svg',
        skillStage: 4,
        description: 'Tailwind CSS를 활용하여 유틸리티 클래스를 사용해 스타일을 간편하게 적용할 수 있습니다.',
      },
      {
        name: 'React Native Expo',
        image: '/assets/expo.png',
        skillStage: 4,
        description: 'Expo를 사용하여 크로스 플랫폼 모바일 애플리케이션을 개발합니다.',
      },
      {
        name: 'next.js',
        image: '/assets/nextjs.svg',
        skillStage: 3,
        description: 'Next.js를 사용하여 서버 사이드 렌더링과 정적 사이트 생성을 구현합니다.',
      },
    ],
  },
  {
    skillName: 'Backend',
    content: [
      {
        name: 'Express',
        image: '/assets/express.svg',
        skillStage: 3,
        description: 'Express를 사용하여 미니멀하고 유연한 Node.js 웹 어플리케이션을 개발합니다.',
      },
      {
        name: 'Spring Boot',
        image: '/assets/spring-boot.png',
        skillStage: 3,
        description:
          'Spring Boot 프레임워크에서 Kotlin과 Exposed ORM을 활용하여 JWT 인증 및 상품 CRUD API를 개발합니다.',
      },
      {
        name: 'node.js',
        image: '/assets/nodejs.svg',
        skillStage: 3,
        description: 'Node.js를 이용하여 서버 측 JavaScript를 개발하고 활용합니다.',
      },
      {
        name: 'mongodb',
        image: '/assets/mongodb.svg',
        skillStage: 3,
        description: 'MongoDB를 사용하여 데이터 저장 및 검색을 수행합니다.',
      },
      {
        name: 'mysql',
        image: '/assets/mysql.svg',
        skillStage: 3,
        description: 'MySQL을 활용하여 구조적인 데이터 관리를 수행합니다.',
      },
      {
        name: 'redis',
        image: '/assets/redis.svg',
        skillStage: 3,
        description: ' Redis를 활용하여 캐싱 및 세션 관리를 수행합니다.',
      },
      {
        name: 'postgresql',
        image: '/assets/postgresql.svg',
        skillStage: 3,
        description: 'PostgreSQL을 사용하여 복잡한 데이터 관리를 수행합니다.',
      },
    ],
  },
  {
    skillName: 'Software',
    content: [
      {
        name: 'figma',
        image: '/assets/figma.svg',
        skillStage: 4,
        description: '피그마를 활용하여 사용자 경험을 고려한 직관적이고 효과적인 인터페이스를 디자인합니다.',
      },
      {
        name: 'photoshop',
        image: '/assets/photoshop.svg',
        skillStage: 5,
        description:
          '사진 및 그래픽 이미지를 편집하고 보정하여 시각물을 생성합니다.  다양한 레이어를 활용하여 디자인 요소를 조합하고 편집합니다.',
      },
      {
        name: 'postman',
        image: '/assets/getpostman.svg',
        skillStage: 4,
        description: ' 포스트맨을 사용하여 API 엔드포인트를 호출하고 요청 및 응답을 테스트합니다.',
      },
    ],
  },
];
